import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { useIntl } from "react-intl"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import GoTo from "../components/goTo"

export default function AboutUsPage({ data }) {
  const intl = useIntl()
  return (
    <React.Fragment>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "about.title" })}
      />
      <Layout intl={intl}>
        <HeroImage
          title={intl.formatMessage({ id: "about.title" })}
          caption={intl.formatMessage({ id: "about.caption" })}
          image={data.heroImage}
        />

        <section
          className="container mx-auto px-5 my-20 text-gray-700 font-light text-xl"
          style={{ maxWidth: "700px" }}
        >
          <p className="my-4">
            {intl.formatMessage({ id: "about.first_paragraph" })}
          </p>
          <p className="my-4">
            {intl.formatMessage({ id: "about.second_paragraph" })}
          </p>
          <p className="my-4">
            {intl.formatMessage({ id: "about.third_paragraph" })}
          </p>
        </section>

        <section className="flex flex-wrap">
          <div className="w-full md:w-1/2">
            <GoTo
              image={data.heroImage}
              page="/activities/"
              title={intl.formatMessage({ id: "activities.title" })}
              caption={intl.formatMessage({ id: "activities.caption" })}
              button={intl.formatMessage({ id: "activities.button" })}
            />
          </div>
          <div className="w-full md:w-1/2">
            <GoTo
              image={data.expertise}
              page="/expertise/"
              title={intl.formatMessage({ id: "expertise.title" })}
              caption={intl.formatMessage({ id: "expertise.caption" })}
              button={intl.formatMessage({ id: "expertise.button" })}
            />
          </div>
        </section>
      </Layout>
    </React.Fragment>
  )
}

export const query = graphql`
  {
    heroImage: file(relativePath: { eq: "about/tentang-yayasan.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    expertise: file(relativePath: { eq: "expertise/kepakaran.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
